import React, { useState, useEffect } from 'react';
import Layout from '../layout/Layout'
import { Grid, Typography } from '@mui/material'
import { GrHomeRounded } from 'react-icons/gr'
import { FaRankingStar } from 'react-icons/fa6'
// import { Pie } from 'react-chartjs-2';
import config from '../../config';
import './informacion.css'
import { Helmet } from 'react-helmet';
import { BsPeople } from 'react-icons/bs';
import { GiCharacter } from 'react-icons/gi';
import { HiOutlineStatusOnline } from 'react-icons/hi';
import { MdOutlineVerified } from 'react-icons/md';

function Informacion(props) {
    const [selectedCategory, setSelectedCategory] = useState('General');
    const [generalData, setGeneralData] = useState(null);

    useEffect(() => {
        // Aquí haces la llamada al servicio y actualizas el estado con los datos devueltos
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.apiBaseUrl}/info`);
                const data = await response.json();
                setGeneralData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
                // En caso de error, establece los datos generales como nulos
                setGeneralData(null);
            }
        };

        fetchData();
    }, []);

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    // const data = {
    //     labels: ['Guerreros', 'Magos', 'Elfas', 'Gladiadores', 'Dark Lords'],
    //     datasets: [
    //         {
    //             label: 'My First Dataset',
    //             data: [
    //                 generalData ? generalData.personajesGuerreros : 1,
    //                 generalData ? generalData.personajesMagos : 1,
    //                 generalData ? generalData.personajesElfas : 1,
    //                 generalData ? generalData.personajesGladiadores : 1,
    //                 generalData ? generalData.personajesDarkLords : 1
    //             ],
    //             backgroundColor: [
    //                 'rgba(255, 99, 132 )',
    //                 'rgba(54, 162, 235 )',
    //                 'rgba(255, 206, 86 )',
    //                 'rgba(75, 192, 192 )',
    //                 'rgba(153, 102, 255 )',
    //                 'rgba(255, 159, 64 )',
    //             ],
    //             borderColor: [
    //                 'rgba(255, 99, 132 )',
    //                 'rgba(54, 162, 235 )',
    //                 'rgba(255, 206, 86 )',
    //                 'rgba(75, 192, 192 )',
    //                 'rgba(153, 102, 255 )',
    //                 'rgba(255, 159, 64)',
    //             ],
    //             borderWidth: 1,
    //         },
    //     ],
    // };

    // const PieChart = () => (
    //     <>
    //         <Pie data={data} />
    //     </>
    // );
    console.log('generalData', generalData)
    return (
        <Layout tyExpanded={props.tyExpanded} setExpanded={props.setExpanded} isMobile={props.isMobile}>
            <Helmet>
                <title>Mu Serendipia | Información</title>
                <meta name="description" content="Encuentra toda la información sobre Mu Serendipia. Configuración del servidor, resets, stats, mapas, eventos, monedas, atajos y comandos." />
                <meta name="keywords" content="mu serendipia, información, configuración del servidor, resets, stats, mapas, eventos, monedas, atajos, comandos, mu online" />
            </Helmet>
            <Grid style={{ display: 'flex', flexDirection: 'column', gap: '35px', padding: props.isMobile ? '2rem 1rem' : '2rem 5.8rem' }} >



                {selectedCategory === 'General' && (
                    <div className="usersettings-settings">
                        <div className="usersettings-change-avatar">
                            <h3>Datos generales</h3>
                            <div className="usersettings-card">
                                <div style={{ textAlign: "center", display: 'flex', alignItems: 'center', gap: '10px', width: '80%', justifyContent: 'space-between', padding: '15px 15px', borderRadius: '5px' }}>

                                    <Grid style={{ display: 'flex', gap: '10px', alignItems: 'center', padding: '0px 15px', width: '20%', backgroundColor: '#171717', padding: 10, borderRadius: 10 }}>

                                        <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                                            <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>

                                                <Typography style={{ fontWeight: 600, fontSize: '1.25rem' }}>{generalData ? generalData.cuentasCreadas : '--'}</Typography>
                                                <BsPeople style={{ fontWeight: 600, fontSize: '1.25rem' }} />

                                            </Grid>

                                            <Typography>Total Registrados</Typography>
                                        </Grid>

                                    </Grid>


                                    <Grid style={{ display: 'flex', gap: '10px', alignItems: 'center', padding: '0px 15px', width: '20%', backgroundColor: '#171717', padding: 10, borderRadius: 10 }}>

                                        <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                                            <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>

                                                <Typography style={{ fontWeight: 600, fontSize: '1.25rem' }}>{generalData ? generalData.personajesCreados : '--'}</Typography>
                                                <GiCharacter style={{ fontWeight: 600, fontSize: '1.25rem' }} />
                                            </Grid>

                                            <Typography>Total Personajes</Typography>
                                        </Grid>

                                    </Grid>


                                    <Grid style={{ display: 'flex', gap: '10px', alignItems: 'center', padding: '0px 15px', width: '20%', backgroundColor: '#171717', padding: 10, borderRadius: 10 }}>

                                        <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                                            <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>

                                                <Typography style={{ fontWeight: 600, fontSize: '1.25rem' }}>{generalData ? generalData.guildsCreados : '--'}</Typography>
                                                <GiCharacter style={{ fontWeight: 600, fontSize: '1.25rem' }} />
                                            </Grid>

                                            <Typography>Total Guilds</Typography>
                                        </Grid>

                                    </Grid>

                                    <Grid style={{ display: 'flex', gap: '10px', alignItems: 'center', padding: '0px 15px', width: '20%', backgroundColor: '#171717', padding: 10, borderRadius: 10 }}>

                                        <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                                            <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>

                                                <Typography style={{ fontWeight: 600, fontSize: '1.25rem' }}>{generalData ? generalData.jugadoresOnline : '--'}</Typography>
                                                <span id="pulsatingDot" style={{ marginRight: '5px' }} />


                                            </Grid>

                                            <Typography>Usuarios online
                                            </Typography>
                                        </Grid>

                                    </Grid>
                                </div>
                            </div>
                        </div>
                        <div className="usersettings-change-avatar">
                            <h3>Configuración del Servidor</h3>
                            <div className="usersettings-card">
                                <div style={{ textAlign: "left", padding: '10px' }}>
                                    <Typography>Versión: Legendaria (única)</Typography>
                                    <Typography>Experiencia: 100x</Typography>
                                    <Typography>Drop: 40%</Typography>
                                    <Typography>Party Bonus: 1% de experiencia adicional por cada jugador del party sin distinguir razas.</Typography>
                                    <Typography>Límite de Zen: Infinito. Puedes obtener, intercambiar y almacenar tanto zen como desees.</Typography>
                                    <Typography>Creación de personajes: no hay restricciones de nivel, puedes elegir cualquiera de nuestras 5 razas al momento de crear tu cuenta.</Typography>
                                </div>
                            </div>
                        </div>

                        <div className="usersettings-settings">
                            <div className="usersettings-change-avatar">
                                <h3>Resets</h3>
                                <div className="usersettings-card">
                                    <div style={{ textAlign: "left", padding: '10px' }}>
                                        <Typography>Para realizar un reset, es necesario alcanzar el nivel 400.</Typography>
                                        <Typography>La cantidad máxima de resets permitidos es 400 ("maxear").</Typography>
                                        <Typography>Cada reset tiene un coste de zen asociado.</Typography>
                                        <Typography>El reset borra stats y otorga 655 puntos (818 para Dark Lords).</Typography>
                                        <Typography>Puedes ver los requisitos para tu próximo reset y realizarlo en "Reset NPC", ubicado en la entrada del bar Lorencia.</Typography>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="usersettings-settings">
                            <div className="usersettings-change-avatar">
                                <h3>Stats</h3>
                                <div className="usersettings-card">
                                    <div style={{ textAlign: "left", padding: '10px' }}>
                                        <Typography>Todas las razas reciben 5 puntos para repartir por cada nivel obtenido.</Typography>
                                        <Typography>El máximo para cada stat es 65.500 puntos.</Typography>
                                        <Typography>Puedes repartir puntos de forma masiva y sin costo utilizando la tecla 'S' en el juego.</Typography>
                                    </div>
                                </div>
                            </div>
                        </div>                       

                        <div className="usersettings-settings">
                            <div className="usersettings-change-avatar">
                                <h3>Atajos y Comandos</h3>
                                <div className="usersettings-card">
                                    <div style={{ textAlign: "left", padding: '10px' }}>
                                        <Typography>Control de sonido: Utiliza las teclas F1 (Activar), F2 (Bajar Volumen), F3 (Subir Volumen) y F4 (Silencio) para ajustar el sonido.</Typography>
                                        <Typography>Autoataque: Presiona la tecla F9 para activar o desactivar la función de autoataque.</Typography>
                                        <Typography>Minimizer: Presione la tecla F10 para minimizar el juego a la bandeja del sistema.</Typography>
                                        <Typography>Comando /post: Envía un mensaje a todos los jugadores en el servidor. Costo: 10.000.000 zen.</Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </Grid>
        </Layout>
    )
}

export default Informacion