import React, { useEffect, useState, useContext } from 'react'
import Layout from '../layout/Layout'
import { Box, Button, Grid, LinearProgress, Tooltip, Typography } from '@mui/material'
import crearCuenta from './creatucuentaia.png'
import descargarCliente from './descargarclienteia.png'
import ComenzaJugar from './comenzaajugaria.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './home.css'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'
import { FaDiscord, FaRegNewspaper } from 'react-icons/fa'
import Auth from '../Auth/Auth'
import config from '../../config';
import AuthContext from '../../AuthContext';
import { Helmet } from 'react-helmet';
import foto1 from './foto1.webp';
import foto2 from './foto2.webp';
import foto3 from './foto3.webp';
import foto4 from './foto4.webp';
import logoFooter from './serendepia2.png'
import foto1mu from './smlorencia.jpg';
import foto2mu from './foto2mu.webp';
import back from './MUOnlineTrailer.mp4'
import Razasection from './Razasection'
import { Width } from 'devextreme-react/cjs/chart'
function Home(props) {
    const { user } = useContext(AuthContext);
    const CountdownTimer = ({ targetDate }) => {
        const calculateTimeLeft = () => {
            const difference = +new Date(targetDate) - +new Date();
            let timeLeft = {};
            if (difference > 0) {
                timeLeft = {
                    Días: Math.floor(difference / (1000 * 60 * 60 * 24)),
                    Horas: Math.floor((difference / (1000 * 60 * 60)) % 24),
                    Minutos: Math.floor((difference / 1000 / 60) % 60),
                    Segundos: Math.floor((difference / 1000) % 60),
                };
            }
            return timeLeft;
        };
        const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
        useEffect(() => {
            const timer = setTimeout(() => {
                setTimeLeft(calculateTimeLeft());
            }, 1000);
            return () => clearTimeout(timer);
        });
        const timerComponents = [];
        Object.keys(timeLeft).forEach((interval) => {
            if (!timeLeft[interval]) {
                return;
            }
            timerComponents.push(
                <div key={interval} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0 10px' }}>
                    <span style={{ color: 'white', fontSize: props.isMobile ? '16px' : '24px', fontWeight: 'bold' }}>{timeLeft[interval]}</span>
                    <span style={{ color: 'white', fontSize: props.isMobile ? '14px' : '18px', textTransform: 'uppercase' }}>{interval}</span>
                </div>
            );
        });

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '25vh',
                fontFamily: 'Arial, sans-serif'
            }}>
                {
                    timerComponents.length > 0 && (
                        <div style={{

                            width: props.isMobile ? '70%' : '10%',
                            background: 'red',  // Adjust the background overlay
                            padding: '10px',
                            borderRadius: '10px',
                            color: 'white',
                            position: 'relative',
                            textAlign: 'center',
                            top: '5%'
                        }}>La Beta finaliza en</div>
                    )
                }

                {timerComponents.length ? (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        width: props.isMobile ? '80%' : '30%',
                        background: 'rgba(0, 0, 0, 0.7)',  // Adjust the background overlay
                        padding: '20px',
                        borderRadius: '10px',
                        border: '2px solid #FFD700'
                    }}>
                        {timerComponents}
                    </div>
                ) : (
                    <Grid style={{ transition: 'transform 0.3s ease, filter 0.3s ease' }} className='img-bannerPrincipal' >
                        <Typography style={{ width: '100%', textAlign: 'left', fontSize: '80px', lineHeight: '80px', fontWeight: 800 }}>REGÍSTRATE EN MU ONLINE SERENDIPIA</Typography>
                    </Grid>
                )
                }
            </div >
        );
    };
    const targetDate = new Date('July 26, 2024 20:00:00');
    const [news, setNews] = useState([]);
    const [formData, setFormData] = useState({
        title: '',
        content: '',
        author: '',
        icon: ''
    });
    const fetchNews = async () => {
        try {
            const response = await axios.get('https://backmu.vercel.app/news');
            // Ordenar las noticias por la fecha de creación en orden descendente (primero la más reciente)
            const sortedNews = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

            setNews(sortedNews);
        } catch (error) {
            console.error('Error al obtener noticias:', error);
        }
    };
    const handleCreateNews = async () => {
        try {
            const response = await axios.post('https://backmu.vercel.app/news', formData);
            console.log(response.data);
            fetchNews();
            setFormData({ title: '', content: '', author: '', icon: '' });
            setHabilitarCrear(!habilitarCrear)
        } catch (error) {
            console.error('Error al crear noticia:', error);
        }
    };
    const handleDeleteNews = async (id) => {
        try {
            const response = await axios.delete(`https://backmu.vercel.app/news/${id}`);
            console.log(response.data);
            fetchNews();
        } catch (error) {
            console.error('Error al eliminar noticia:', error);
        }
    };
    useEffect(() => {
        fetchNews();
    }, []);

    const [guias, setGuias] = useState()


    const fetchCatGuias = async () => {

        try {
            const response = await axios.get('https://backmu.vercel.app/ayudaGuia');
            setGuias(response.data);
        } catch (error) {
            console.error('Error al obtener categorias:', error);
        }
    };

    useEffect(() => {
        fetchCatGuias()
    }, [])

    const selectRandomItems = (array = [], numberOfItems) => {
        if (array.length < numberOfItems) {
            throw new Error('El array no tiene suficientes elementos');
        }

        const shuffled = array.slice().sort(() => 0.5 - Math.random());
        return shuffled.slice(0, numberOfItems);
    };
    const [randomItems, setRandomItems] = useState([])

    const seleccionarItems = () => {
        try {
            // Asegúrate de que `guias` esté definido y sea un array
            if (!Array.isArray(guias)) {
                throw new Error('La propiedad guias no es un array');
            }

            const items = selectRandomItems(guias, 2);

            setRandomItems(items);
        } catch (error) {
            console.error(error.message);
        }
    };

    useEffect(() => {
        seleccionarItems()
    }, [guias])



    const [showPopupAuth, setShowPopupAuth] = useState(false);
    const [type, setType] = useState(0);

    function togglePopupAuth(type) {
        setShowPopupAuth(!showPopupAuth);
        setType(type);
    }
    const [habilitarCrear, setHabilitarCrear] = useState()
    const transformarFecha = (fecha) => {
        const fechaObj = new Date(fecha);
        const meses = [
            'ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN',
            'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'
        ];
        const dia = String(fechaObj.getDate()).padStart(2, '0'); // Asegura que el día tenga dos dígitos
        const mes = meses[fechaObj.getMonth()];
        const hora = String(fechaObj.getHours()).padStart(2, '0'); // Asegura que la hora tenga dos dígitos
        const minutos = String(fechaObj.getMinutes()).padStart(2, '0'); // Asegura que los minutos tengan dos dígitos
        return `${dia}/${mes} ${hora}:${minutos}`;
    };

    // Lista de URLs de iconos (imágenes PNG subidas a Cloudinary)
    const iconUrls = [
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241438/z7olfgbdbbsyihvhwfhu.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241438/edcecrtygii2iusno7ko.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241438/vd3l1urzxeqoii94bcon.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241438/wok7babib5nyy5sh6ce4.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241438/ojfbuhx682pvcmfcsfbx.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241438/y7pfjwg3rflmowiynku5.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241438/mlsjwdu4rb8bfp7zf3cr.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241438/ze3muwfhxrmm6xxjulgd.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241438/snog5rx0kohogpsmzsvd.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241439/np5p6gh1xfie8est7mrx.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241439/qtruj13sw0l7kf9etuwk.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241439/pipxlq8shpoxlqd7hzbj.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241439/ywilvb49xchublgwrl73.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241439/fjaocuxiwalqnooiwy1w.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241439/eympsff757okytwston4.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241439/pxukna32u2hwfn4pniwj.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241439/mflftinu8xqebvtrdebe.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241439/rjpabojrglyonow9nnc8.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241440/zfkazwtqpsz85b98sdxa.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241440/lur7cxuscfsjewth6ryu.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241440/rndlnldgvy3mm4norf9c.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241440/mro0eoh9t6qmzpotzt0x.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241440/vdb0m2iwv9np3peuli59.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241440/bezzn3l8pa2q4c0zfhzk.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241440/gs5ww51vthpdkmuv3oel.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241440/bvdrzz15chkfvcgahrtm.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241440/ydwqblpiotukjgokgajt.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241440/wp2qshycii5cwgsaqqb7.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241440/itpyykbdt6cnbqgldmhb.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241441/ihggjun8w3wy1uogskga.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241440/hoiozycu8qxqwmtlsu6g.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241441/bd6noefy22nnbjs9zgfu.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241441/hphysnjhbyetaw8tuofj.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241441/gyoc4g5zqdqf8qzp6gdn.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241441/ltva12ejzdkp9ulltzvh.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241441/ve52xashmnj5x7fw9ety.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241441/ffhruhz83inl9hkc2uks.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241441/zseju1wxdbdqkvlxo0ju.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241441/mu4ezmibfqc5ksotmdxb.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241442/nqwbrt5tzufb1vz1aume.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241442/bxiatwrtjbbkklnlevco.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241442/yviw2fsnbfofo9phqhxx.png',
        'https://res.cloudinary.com/dks7seyl8/image/upload/v1725241442/kklot9y7eaudzioq3e0w.png',

        // Añade más URLs según sea necesario
    ];

    const [selectedIcon, setSelectedIcon] = useState(iconUrls[0]); // Inicializa con el primer icono

    // Función para manejar el cambio de selección
    const handleIconChange = (event) => {
        setFormData({ ...formData, icon: event.target.value })
        setSelectedIcon(event.target.value);
    };
    const navigate = useNavigate();

    const redirigir = (url) => {
        navigate(url);
    };

    
    return (
        <Grid>

            <Layout tyExpanded={props.tyExpanded} setExpanded={props.setExpanded} isMobile={props.isMobile} >
                <Helmet>
                    <title>Mu Serendipia | Inicio</title>
                    <meta name="description" content="Bienvenido a Mu Serendipia. Únete a nuestra comunidad y disfruta del mejor servidor privado de Mu Online. Crea tu cuenta, descarga el cliente y comienza a jugar ahora." />
                    <meta name="keywords" content="mu serendipia, mu online, servidor privado, mmorpg, crear cuenta, descargar cliente, comenzar a jugar, comunidad, juego online" />
                </Helmet>
                <Grid className='banner1' style={{ padding: 0, margin: 0, display: 'flex', flexDirection: props.isMobile ? 'column' : 'row', alignItems: 'center', gap: '15%', justifyContent: 'center' }}>
                    {
                        !props.isMobile &&
                        <img src={logoFooter} style={{ position: 'absolute', left: '15%', top: '-1%', width: '12%', zIndex: 99999 }} />
                    }

                    <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', height: '100%', gap: '15px', width: props.isMobile && '100%' }}  >

                        <Typography style={{ color: 'white', textShadow: '4px 4px 7px rgba(0,0,0,0.6)' }} >Bienvenido</Typography>
                        {
                            user?.username ?

                                <Typography style={{ width: '100%', textAlign: 'left', fontSize: props.isMobile ? '40px' : '80px', lineHeight: props.isMobile ? '40px' : '80px', fontWeight: 800, color: 'white', fontStyle: 'italic', textShadow: '4px 4px 7px rgba(0,0,0,0.6)', overflow: 'hidden', fontFamily: 'Beaufort' }}>BIENVENIDO <br /> A <br />MU SERENDIPIA  </Typography>
                                :
                                <Typography style={{ width: '100%', textAlign: 'left', fontSize: props.isMobile ? '40px' : '80px', lineHeight: props.isMobile ? '40px' : '80px', fontWeight: 800, color: 'white', fontStyle: 'italic', textShadow: '4px 4px 7px rgba(0,0,0,0.6)', overflow: 'hidden', fontFamily: 'Beaufort' }}>REGÍSTRATE <br /> EN <br />MU ONLINE <br /> SERENDIPIA</Typography>
                        }

                        <Typography style={{ color: 'white', textShadow: '4px 4px 7px rgba(0,0,0,0.6)', width: props.isMobile && '100%' }} >Es totalmente gratuito</Typography>
                        {
                            user?.username ?
                                <Button style={{ color: '#0A1428 ', width: '40%', background: '#d3bb8b', height: '8%' }} onClick={() => redirigir('/ayuda')}>APRENDE A JUGAR!</Button>
                                :
                                <Button style={{ color: '#0A1428 ', width: '40%', background: '#d3bb8b', height: '8%' }} onClick={() => togglePopupAuth(1)}>REGISTRARSE</Button>
                        }

                    </Grid>

                    <Grid style={{ display: 'flex', width: props.isMobile ? '100%' : '30%', flexDirection: 'column', gap: '10px', padding: '15px 10px', borderRadius: '5px', height: props.isMobile && '80%' }}>
                        <Grid style={{ padding: '5px', borderBottom: '1px solid #34384363' }} >
                            <Typography style={{ fontWeight: 600, fontSize: '24px', color: 'white', textAlign: 'left', width: '50%', textShadow: '4px 4px 7px rgba(0,0,0,0.6)' }}>Noticias Rápidas!</Typography>
                        </Grid>

                        <Grid style={{ padding: '5px', gap: '10px', display: 'flex', flexDirection: 'column' }}>
                            {
                                (user?.username === 'drako' || user?.username === 'eldenguee') ?
                                    news.length >= 1 ?
                                        <Grid style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                                            <button style={{ width: '30%' }} onClick={() => setHabilitarCrear(!habilitarCrear)} > {habilitarCrear ? 'Cerrar' : 'Crear Noticia'}</button>
                                            {news.map((item) => (
                                                <Grid style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                    <Grid style={{ display: 'flex', alignItems: 'center', border: '1px solid #343843', borderRadius: '5px', padding: '5px' }}>
                                                        <Grid style={{ width: '10%', display: 'flex', justifyContent: 'center' }}>
                                                            {item?.icon ?
                                                                <img src={item?.icon} style={{ width: '30%' }} />
                                                                :
                                                                <FaRegNewspaper style={{ color: 'white', fontSize: '30px', margin: '0 auto' }} />
                                                            }
                                                        </Grid>
                                                        <Grid style={{ width: '90%', display: 'flex', flexDirection: 'column' }}>
                                                            {/* <Typography variant='h5' style={{ color: 'white', fontSize: '20px' }}>
                                                                {item.title}
                                                            </Typography> */}
                                                            <Typography style={{ color: 'white' }}>
                                                                {item.content}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <button onClick={() => handleDeleteNews(item._id)} style={{ width: '10%' }}>Eliminar</button>
                                                </Grid>
                                            ))}
                                        </Grid>
                                        :
                                        (user?.username === 'drako' || user?.username === 'eldenguee') ?
                                            <Grid style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                <button style={{ width: '10%' }} onClick={() => setHabilitarCrear(!habilitarCrear)} > {habilitarCrear ? 'Cerrar' : 'Crear Noticia'}</button>
                                                <Typography style={{ color: 'white', fontStyle: 'italic' }}> No existen noticias en estos momentos</Typography>
                                            </Grid>
                                            : <Typography style={{ color: 'white', fontStyle: 'italic' }}> No existen noticias en estos momentos</Typography>
                                    :
                                    news.length >= 1 ?
                                        news.map((item, index) => (
                                            <Grid style={{ display: 'flex', alignItems: 'center', borderRadius: '5px', padding: '5px', gap: '10px', backgroundColor: index % 2 === 0 ? '#202329a8' : '#080808d1' }}>

                                                <Grid style={{ width: '25%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '15px', justifyContent: 'flex-start' }}>
                                                    {item?.icon ?
                                                        <img src={item?.icon} style={{ width: '30%' }} />
                                                        :
                                                        <FaRegNewspaper style={{ color: 'white', fontSize: '30px', margin: '0 auto' }} />
                                                    }

                                                    <Typography variant='h5' style={{ color: 'white', fontSize: '16px' }}>
                                                        {transformarFecha(item.createdAt)}
                                                    </Typography>
                                                </Grid>
                                                <Grid style={{ width: '90%', display: 'flex', flexDirection: 'column' }}>
                                                    {/* <Typography variant='h5' style={{ color: 'white', fontSize: '20px' }}>
                                                        {item.title}
                                                    </Typography> */}
                                                    <Typography style={{ color: 'white' }}>
                                                        {item.content}
                                                    </Typography>
                                                </Grid>

                                            </Grid>
                                        ))
                                        :
                                        <Typography style={{ color: 'white', fontStyle: 'italic' }}> No existen noticias en estos momentos</Typography>
                            }
                            {(user?.username === 'drako' || user?.username === 'eldenguee') && habilitarCrear ?
                                <Grid style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    <h2>Crear Noticia</h2>
                                    <input type="text" name="title" placeholder="Título" value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })} />
                                    <textarea type="textarea" name="content" placeholder="Contenido" value={formData.content} onChange={(e) => setFormData({ ...formData, content: e.target.value })} />
                                    <h2>Selecciona un Icono</h2>
                                    {/* Selector de íconos */}
                                    <select onChange={handleIconChange} value={selectedIcon}>
                                        {iconUrls.map((url, index) => (
                                            <option key={index} value={url}>
                                                Icono {index + 1}
                                            </option>
                                        ))}
                                    </select>

                                    {/* Vista previa del icono seleccionado */}
                                    <div style={{ marginTop: '20px' }}>
                                        <h3>Icono Seleccionado:</h3>
                                        <img src={selectedIcon} alt="Icono Seleccionado" style={{ width: '100px', height: '100px' }} />
                                    </div>
                                    <button onClick={handleCreateNews}>Crear Noticia</button>
                                </Grid>
                                : <></>
                            }
                        </Grid>
                    </Grid>

                </Grid>
                <Grid style={{ display: 'flex', flexDirection: 'column', gap: '35px', padding: props.isMobile ? '2rem 1rem' : '2rem 5.8rem', height: !props.isMobile && '673px', alignItems: 'center', justifyContent: 'center' }}>
                    {
                        props.isMobile && !user?.username &&
                        <Swiper
                            spaceBetween={5}
                            slidesPerView={1}
                            navigation
                            pagination
                            scrollbar={{ draggable: true }}
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                            style={{ width: '100%', height: 'auto' }}
                        >
                            <SwiperSlide style={{ color: 'white' }}>
                                <img src={crearCuenta} alt="Crear Cuenta" style={{ borderRadius: '5px', width: '100%' }} />
                            </SwiperSlide>
                            <SwiperSlide style={{ color: 'white' }}>
                                <img src={descargarCliente} alt="Descargar Cliente" style={{ borderRadius: '5px', width: '100%' }} />
                            </SwiperSlide>
                            <SwiperSlide style={{ color: 'white' }}>
                                <img src={ComenzaJugar} alt="Comenzar a Jugar" style={{ borderRadius: '5px', width: '100%' }} />
                            </SwiperSlide>
                        </Swiper>
                    }
                    {/* {
                        !props.isMobile && !user?.username &&
                        <Grid style={{ display: 'flex', width: '100%', justifyContent: 'space-between', gap: '15px' }}>

                            <Grid style={{ position: 'relative', display: 'inline-block', width: '25%', cursor: 'pointer', borderRadius: '5px' }} onClick={() => togglePopupAuth(1)}>
                                <img src={foto1} alt="VIP" style={{
                                    borderRadius: '5px', width: '100%', height: 'auto', transition: 'transform 0.3s ease, filter 0.3s ease', // Animación suave
                                    filter: 'brightness(70%)'
                                }} className='img-efecto' />
                                <h6 style={{
                                    position: 'absolute',
                                    top: '30%',
                                    left: '10px',
                                    margin: 0,
                                    color: '#ffffffcc',
                                    fontSize: '16px',
                                    padding: '5px 10px',
                                    borderRadius: '5px',
                                }}>
                                    Registrate gratis!
                                </h6>
                            </Grid>

                            <Link to="/descargas" style={{ position: 'relative', display: 'inline-block', width: '25%', cursor: 'pointer', borderRadius: '5px' }}>
                                <img src={foto2} alt="VIP" style={{
                                    borderRadius: '5px', width: '100%', height: 'auto', transition: 'transform 0.3s ease, filter 0.3s ease', // Animación suave
                                    filter: 'brightness(70%)'
                                }} className='img-efecto' />
                                <h6 style={{
                                    position: 'absolute',
                                    top: '30%',
                                    left: '10px',
                                    margin: 0,
                                    color: '#ffffffcc',
                                    fontSize: '16px',
                                    padding: '5px 10px',
                                    borderRadius: '5px',
                                }}>
                                    Descarga el juego!
                                </h6>
                            </Link>

                            <Link to="/" style={{ position: 'relative', display: 'inline-block', width: '25%', cursor: 'pointer', borderRadius: '5px' }}>
                                <img src={foto3} alt="VIP" style={{
                                    borderRadius: '5px', width: '100%', height: 'auto', transition: 'transform 0.3s ease, filter 0.3s ease', // Animación suave
                                    filter: 'brightness(70%)'
                                }} className='img-efecto' />
                                <h6 style={{
                                    position: 'absolute',
                                    top: '30%',
                                    left: '10px',
                                    margin: 0,
                                    color: '#ffffffcc',
                                    fontSize: '16px',
                                    padding: '5px 10px',
                                    borderRadius: '5px',
                                }}>
                                    Como jugar?
                                </h6>
                            </Link>
                            <Link to="/" style={{ position: 'relative', display: 'inline-block', width: '25%', cursor: 'pointer', borderRadius: '5px' }}>
                                <img src={foto4} alt="VIP" style={{
                                    borderRadius: '5px', width: '100%', height: 'auto', transition: 'transform 0.3s ease, filter 0.3s ease', // Animación suave
                                    filter: 'brightness(70%)'
                                }} className='img-efecto' />
                                <h6 style={{
                                    position: 'absolute',
                                    top: '30%',
                                    left: '10px',
                                    margin: 0,
                                    color: '#ffffffcc',
                                    fontSize: '16px',
                                    padding: '5px 10px',
                                    borderRadius: '5px',
                                }}>
                                    Hazte VIP!
                                </h6>
                            </Link>
                        </Grid>
                    } */}


                    <Typography style={{ color: 'white', textAlign: 'center', fontFamily: 'Beaufort', fontSize: '24px' }}>Te damos la bienvenida a Mu Serendipia</Typography>
                    <Typography style={{ color: 'white', textAlign: 'center', fontSize: '80px', fontFamily: 'Beaufort' }}>APRENDIENDO LO BASICO</Typography>
                    <Typography style={{ color: 'white', textAlign: 'center', fontFamily: 'Beaufort', fontSize: '24px' }}>Hay mucho que aprender en el continente de Mu Serendipia, vamos a empezar con lo más basico para familiarizarte
                    </Typography>

                    {/* <Grid style={{ display: 'flex', justifyContent: 'space-between', flexDirection: props.isMobile && 'column', gap: props.isMobile && '10px', gap: '15px' }}>


                        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: '15px', width: props.isMobile ? '100%' : '50%', padding: '15px 10px', border: '1px solid #343843', borderRadius: '5px' }}>

                            <Grid style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography style={{ fontWeight: 600, fontSize: '24px', color: 'white', textAlign: 'left', width: '50%' }}>Guías recomendadas</Typography>

                                <Link to={'/ayuda'} style={{ backgroundColor: 'transparent', color: 'white', textDecoration: 'none' }}>
                                    Ver todo
                                </Link>

                            </Grid>
                            <Grid style={{
                                display: 'flex',
                                width: '100%',
                                gap: '10px',
                            }}>
                                {
                                    randomItems?.map((item) => (
                                        <Link to={`/guia/${item?._id}`} key={item?._id} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: '10px', width: '50%', textDecoration: 'none', borderRadius: '5px' }}>
                                            <img src={item?.imagen ?? foto1mu} className='img-efecto' style={{ transition: 'transform 0.3s ease, filter 0.3s ease', borderRadius: '5px' }} />
                                            <Typography style={{ color: 'red' }}>{item?.titulo}</Typography>
                                        </Link>
                                    ))
                                }



                            </Grid>
                        </Grid>
                    </Grid> */}
                </Grid>
                <img src='https://uploadcdn.webzen.com/Files/Clara/WebzenGP/webmanager/SVR002/s1622upmain.jpg' style={{ width: '100%' }} />



                <div className="video-background-container">
                    <video className="background-video" autoPlay loop muted>
                        <source src={back} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                    {/* Overlay text */}
                    <div className="overlay-content">
                        <Typography style={{ color: 'white', textAlign: 'center', fontSize: '40px', fontFamily: 'Beaufort', textShadow: 'rgb(0, 0, 0) 0px 1px 0px' }}>COMO JUGAR MU SERENDIPIA?</Typography>
                        <Typography style={{ color: 'white', textAlign: 'center', fontFamily: 'Beaufort', textShadow: 'rgb(0, 0, 0) 0px 1px 0px' }}>Entiende el objetivo principal del juego, que generalmente implica subir de nivel, mejorar tus armaduras y enfrentar desafíos en el mundo de Mu Serendipia
                        </Typography>
                    </div>
                </div>

                <Razasection />

                
                {
                    showPopupAuth === true && (
                        <Auth
                            isMobile={props.isMobile}
                            typeDefault={type}
                            closePopup={() => togglePopupAuth()}
                        />
                    )
                }
            </Layout >
        </Grid >

    )
}
export default Home